

































































































































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapGetters, mapState } from 'vuex'
import { NeedTraining } from '@/api/interfaces/needtraining'
import TopBar from '@/components/organisms/o-top-bar.vue'
import MFilterList from '@/components/molecules/m-filter-list.vue'
import deleteButton from '@/components/molecules/m-delete-button.vue'
import {
  FilterListState,
  FiltersParams,
} from '@/views/dashboard/trainee/store/filterList'
import { constructUrlQueryString, QueryParams } from '@/store/api-plateform-utils'
import {
  PaginationOptions,
  parseQuery,
  usePagination,
} from '@/composables/pagination'
import Pagination from '@/components/base/Pagination.vue'
import router from '@/router'
import { mapFields } from 'vuex-map-fields'
import { NeedTrainingStatus } from '@/api/interfaces/needtrainingstatus'
import { userCanAccess, userHasRole } from '@/composables/UserGrant'

export default defineComponent({
  setup (props, ctx) {
    const canAddTrainee = () => userCanAccess('Trainee Create')
    const canExportTrainee = () => userHasRole('ROLE_EXPORT_TRAINEE')
    const canDeleteTrainee = () => userHasRole('ROLE_DELETE_TRAINEE')
    const query = {
      ...router.app.$route.query,
    }
    const totalItems = () => {
      return ctx.root.$store.state.traineeList.totalItems
    }
    delete query.trainee
    delete query.status
    delete query.trainingSession
    delete query.company
    delete query['company.accountType']
    delete query.businessIntroducer
    delete query.trainingLocation
    delete query.carDealer
    delete query.trainingType
    delete query.carNumberPlate
    const {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      }: PaginationOptions = usePagination(
    totalItems,
    parseQuery(query),
  ) as PaginationOptions
    return {
      paginationOptions,
      paginationRequestParams,
      nbPages,
      onSortBy,
      onSortDesc,
      canAddTrainee,
      canExportTrainee,
      canDeleteTrainee,
    }
  },
  name: 'TraineeList',
  components: {
    MFilterList,
    TopBar,
    Pagination,
    deleteButton,
    massReminderForm: () => import('./components/MassReminderForm.vue'),
    massChangeStatusForm: () => import('./components/MassChangeStatusForm.vue'),
  },
  data () {
    return {
      showCreateEditReminderForm: false,
      showCreateChangeStatusForm: false,
      title: this.$t('trainee.list.title_header'),
      headers: [
        {
          text: this.$t('trainee.list.headers.lastName'),
          align: 'start',
          value: 'trainee.lastName',
        },
        {
          text: this.$t('trainee.list.headers.firstName'),
          value: 'trainee.firstName',
        },
        {
          text: this.$t('trainee.list.headers.phoneNumber'),
          value: 'telephone',
        },
        {
          text: this.$t('trainee.list.headers.email'),
          value: 'trainee.email',
        },
        {
          text: this.$t('trainee.list.headers.company'),
          value: 'company.name',
        },
        {
          text: this.$t('trainee.list.headers.trainingType'),
          value: 'trainingType.name',
        },
        {
          text: this.$t('trainee.list.headers.place'),
          value: 'trainingSession.trainingLocation.name',
        },
        {
          text: this.$t('trainee.list.headers.dateTrainingSessionStartAt'),
          value: 'trainingSession.startAt',
          sortable: false,
        },
        {
          text: this.$t('trainee.list.headers.currentStatus'),
          value: 'status.status',
        },
        {
          text: this.$t('trainee.list.headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      debounceTimeoutId: 0,
    }
  },
  created () {
    this.queryToStore()
    this.resetTrainee()
    this.resetNeedTrainingForm({ trainee: null, trainingType: null })
  },
  computed: {
    ...mapState('status', {
      statusesList: 'list',
    }),
    ...mapState('traineeList', {
      traineeList: 'list',
      totalItems: 'totalItems',
      loading: 'loading',
      exporting: 'exporting',
    }),
    ...mapState('company', {
      allCompanies: 'list',
    }),
    ...mapState('carDealerList', {
      allCarDealer: 'list',
    }),
    ...mapGetters('filterList', {
      filter: 'getFiltersParams',
    }),
    ...mapGetters('status', {
      totalStatus: 'totalStatus',
      getStatusPocketIri: 'getStatusPocketIri',
    }),
    ...mapFields('filterList', [
      'filter.trainee',
      'filter.commandNoOneOrNoTwo',
      'filter.businessIntroducer',
      'filter.company',
      'filter.accountType',
      'filter.trainingType',
      'filter.status',
      'filter.carDealer',
      'filter.trainingSession.startAt',
      'filter.traineeImport',
      'filter.carNumberPlate',
    ]),
    baseRequest (): QueryParams {
      const request: Record<string, unknown> = {
        ...(this.filter as FilterListState),
        ...this.paginationRequestParams,
      }
      return request as QueryParams
    },
    exportTraineesUrl (): string {
      const option = constructUrlQueryString(this.baseRequest as QueryParams)
      return `/api/need_trainings/export${option}`
    },
  },
  watch: {
    totalItems (newValue: number, oldValue: number) {
      if (oldValue !== newValue && !!oldValue) {
        // if new search result, reset pagination except for the first
        this.paginationOptions.page = 1
      }
    },
    paginationOptions: {
      handler () {
        // once filter.status is set by MFilterList, it will launch the first request
        if (this.status) {
          this.addParamsToLocation()
          this.load(this.baseRequest)
        }
      },
      deep: true,
    },
    filter: {
      handler () {
        window.clearTimeout(this.debounceTimeoutId)
        this.debounceTimeoutId = window.setTimeout(async () => {
          this.addParamsToLocation()
          this.load(this.baseRequest)
        }, 275)
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('carDealerList', {
      loadByIDCarDealer: 'loadByID',
      loadCarDealers: 'load',
    }),
    ...mapActions('company', {
      loadByIDCompany: 'loadByID',
      loadCompanies: 'load',
    }),
    ...mapActions('traineeList', {
      load: 'load',
      deleteById: 'deleteById',
    }),
    ...mapActions('traineeForm', {
      resetTrainee: 'reset',
    }),
    ...mapActions('status', {
      loadNeedTrainingStatuses: 'load',
    }),
    ...mapActions('needTrainingForm', {
      resetNeedTrainingForm: 'reset',
    }),
    async queryToStore () {
      const query = {
        ...router.app.$route.query,
      }
      this.commandNoOneOrNoTwo = query.commandNoOneOrNoTwo
      this.startAt = query.TrainingSessionStartAt || ''
      if (query.company) {
        if (typeof query.company === 'string') {
          this.company = [await this.loadByIDCompany(query.company)]
        } else {
          this.company = await Promise.all(
            (query.company as string[]).map((companyId: string) => {
              return this.loadByIDCompany(companyId)
            }),
          )
        }
      }
      if (query['company.accountType']) {
        this.accountType = query['company.accountType']
      }
      if (query.businessIntroducer) {
        if (typeof query.businessIntroducer === 'string') {
          this.businessIntroducer = [await this.loadByIDCompany(query.businessIntroducer)]
        } else {
          this.businessIntroducer = await Promise.all(
            (query.businessIntroducer as string[]).map(
              (businessIntroducerId: string) => {
                return this.loadByIDCompany(businessIntroducerId)
              },
            ),
          )
        }
      }
      if (query.carDealer) {
        if (typeof query.carDealer === 'string') {
          this.carDealer = [await this.loadByIDCarDealer(query.carDealer)]
        } else {
          this.carDealer = await Promise.all(
            (query.carDealer as string[]).map((carDealerId: string) => {
              return this.loadByIDCarDealer(carDealerId)
            }),
          )
        }
      }
      if (typeof query.trainingType === 'string') {
        this.trainingType = [query.trainingType]
      } else {
        this.trainingType = query.trainingType
      }
      if (query.status && query.status.length > 0) {
        this.status = query.status
      } else {
        const statuses = this.statusesList as NeedTrainingStatus[]
        const statusesFiltered = statuses.filter(x => x.status !== 'Pocket')
        this.status = statusesFiltered.map(x => x['@id'])
      }
      if (query.traineeImport) {
        this.traineeImport = query.traineeImport
      } else {
        this.traineeImport = null
      }
      this.trainee = {
        lastName: query['trainee.lastName'],
        firstName: query['trainee.firstName'],
        email: query['trainee.email'],
        phones: query['trainee.phones'],
      }
    },
    gotToTraineeForm (item: NeedTraining) {
      if (item.id) {
        this.$router.push({
          name: 'Trainee Edit',
          params: { idNeedTraining: item.id.toString() },
        })
      }
    },
    addParamsToLocation () {
      let query: any = {
        ...this.paginationOptions,
        ...(this.filter as FiltersParams),
      }
      query = Object.fromEntries(
        Object.entries(query)
          .filter(([key, value]) => {
            if (key === 'status') {
              // if it is the base value of the filter, we don't want to add it to the url
              return !(
                // the base value of the status is all selected except of the pocket status
                !(value as string[]).some(
                  (status: string) =>
                    status === (this.getStatusPocketIri as string),
                ) &&
                (value as string[]).length === (this.totalStatus as number) - 1
              )
            }
            return !!value
          })
          .map(([key, value]) => {
            return [key, value]
          }),
      )
      router.replace({ name: 'Trainee List', query: query })
    },
    async reloadTrainees () {
      await this.load(this.baseRequest)
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Trainee Edit' || to.name === 'Trainee Create') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
